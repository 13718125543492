import { Box, Button, Text, Layer } from "grommet";
import { Alert, StatusGood } from "grommet-icons";
import { messagesCommon } from "../messages/messagesCommon";

export const ModalWarning = (props) => {


    return (
        <Layer position="center">
            <Box pad="medium" gap="small" width="large">
                <Box gap="medium" align="center">
                    <Alert size="xlarge" color="orange" />
                    <Text>
                        {props.message}
                    </Text>
                    <Box
                            as="footer"
                            gap="small"
                            direction="row"
                            pad={{ top: "medium", bottom: "small" }}
                            alignSelf="center"
                            justify="center"
                    >
                        <Button
                        primary
                        label={messagesCommon.FERMER}
                        onClick={() => props.onClose(false)}
                        />

                        <Button
                            primary
                            label={messagesCommon.VALIDER}
                            onClick={() => props.onClose(true)}
                        />
                    </Box>
                    
                </Box>             
            </Box> 
        </Layer>  
        
    );
};