import { Box, Text, TextInput } from "grommet";

import { TextFormField } from "./inputs/TextFormField";
import { NumberFormField } from "./inputs/NumberFormField";
import { ListFormField } from "./inputs/ListFormField";
import { RadioFormField } from "./inputs/RadioFormField";
import { EmailFormField } from "./inputs/EmailFormField";
import { PhoneFormField } from "./inputs/PhoneFormField";
import { useScreenSize } from "../responsive";
import { DirectionAffectation } from "./inputs/DirectionAffectation";
import { useEffect, useState } from "react";
import { MaskedTextFormField } from "./inputs/MaskedTextFormField";
import { PostalCodeFormField } from "./inputs/PostalCodeFormField";
import { CheckboxGroupFormField } from "./inputs/CheckboxGroupFormField";
import { formCodes } from "./formCodes";
import { messagesAutresRens } from "../messages/messagesAutresRens";
import { CheckboxFormField } from "./inputs/CheckBoxFormField";
import { DateFormField } from "./inputs/DateFormField";

export const AutresRenseignements = (props) => {
    const diplomeTypes = [19, 48];
    const professionTypes = [42, 52, 54];
    const posAdminTypes = [1];
    const paysAffectTypes = [8];
    const gradeTypes = [11];
    const situationFamilialeTypes = [38];
    const situationMilitaireTypes = [45, 88]; // EV5 : ajout de la situation militaire pour concours INTERNE si applicable
    const origineFoncTypes = [2];
    const amenagementTypes = [35, 56];
    const [balancedSize, setBalancedSize] = useState(undefined);

    const regexRules = {
        [formCodes.COMMUNE_AFFECTATION]: "S6Iso",
        texteGradedetail: "nolimit",
        [formCodes.NOM_MERE]: "S2",
        [formCodes.NOM_PERE]: "S2",
    };

    const administrationFields = [
        formCodes.ADRESSE_AFFECTATION,
        formCodes.ADMINISTRATION_EMPLOI,
        formCodes.AFFECTATION_ADMIN,
        formCodes.DIRECTION_AFFECTATION,
        formCodes.PAYS_AFFECTATION,
        formCodes.CODE_POSTAL_AFFECTATION,
        formCodes.COMMUNE_AFFECTATION,
    ];

    const maxLengths = {
        nbrEnfants: 2,
        nbrEnfantsACharge: 2,
        nbrPersonnesHandicapeesACharge: 2,
        engagementSousDrapeauxNbrAnnees: 2,
        travaileurHandicapeNbrAnnee: 2,
        sportifHautNiveauNbrAnnee: 2,
        nombreMatricule: 7,
        texteGradedetail: 250,
        texteLbl11: 255,
        texteLbl12: 255
    };

    useEffect(() => {
        if (props.champs?.length > 0) {
            let actualSize = 0;
            props.champs.forEach(
                (c) => (actualSize += c.type === "OUI_NON" ? 0.5 : 1)
            );
            setBalancedSize(actualSize);
        }
    }, [props.champs]);

    const getRadioBtnForChamp = (champ, label, italicLabel, key) => {
        if (
            champ.codeForm === formCodes.ETAT_DIPLOME ||
            champ.type === "OUI_NON"
        ) {
            let options;
            if (champ.codeForm === formCodes.ETAT_DIPLOME) {
                if (
                    !props.formulaire.diplome ||
                    props.formulaire.diplome?.code === "SANS"
                ) {
                    return undefined;
                }
                options = [
                    { label: "Acquis", value: "acquis" },
                    { label: "En cours", value: "encours" },
                ];

                label = "Diplôme " + label;
            } else {
                options = [
                    { label: "Oui", value: 1 },
                    { label: "Non", value: 0 },
                ];
            }

            return (
                <RadioFormField
                    name={champ.codeForm}
                    key={key}
                    label={label}
                    italicLabel={italicLabel}
                    required={champ.obligatoireSurInternet}
                    options={options}
                />
            );
        }
    };

    const getTextInputForChamp = (champ, label, italicLabel, key) => {
        if (champ.codeForm.includes("mail")) {
            return (
                <EmailFormField
                    key={key}
                    label={label}
                    name={champ.codeForm}
                    italicLabel={italicLabel}
                    required={champ.obligatoireSurInternet}
                />
            );
        } else if (champ.codeForm === formCodes.TELEPHONE_PRO) {
            return (
                <PhoneFormField
                    key={key}
                    label={label}
                    name={champ.codeForm}
                    italicLabel={italicLabel}
                    required={champ.obligatoireSurInternet}
                />
            );
        } else {
            const info =
                champ.codeForm === formCodes.NOM_MERE ||
                champ.codeForm === formCodes.NOM_PERE
                    ? "X si inconnu"
                    : undefined;

            if (
                champ.codeForm === formCodes.AFFECTATION_ADMIN ||
                champ.codeForm === formCodes.ADRESSE_AFFECTATION
            ) {
                return (
                    <>
                        <MaskedTextFormField
                            key={key}
                            label={label}
                            info={info}
                            name={champ.codeForm}
                            italicLabel={italicLabel}
                            required={isActuallyRequired(champ)}
                            regexRule="S6Iso"
                        />
                        <MaskedTextFormField
                            key={key + 300}
                            name={champ.codeForm + "2"}
                            regexRule="S6Iso"
                        />
                    </>
                );
            }
            if (champ.codeForm === formCodes.CODE_POSTAL_AFFECTATION) {
                return (
                    <PostalCodeFormField
                        label={label}
                        required={isActuallyRequired(champ)}
                        maxLength={5}
                        requiredLength={5}
                        name={champ.codeForm}
                        departements={props.referentiel.departements}
                    />
                );
            }
            if (regexRules[champ.codeForm]) {
                return (
                    <MaskedTextFormField
                        key={key}
                        label={label}
                        info={info}
                        name={champ.codeForm}
                        italicLabel={italicLabel}
                        required={isActuallyRequired(champ)}
                        regexRule={regexRules[champ.codeForm]}
                        maxLength={maxLengths[champ.codeForm]}
                    />
                );
            }
            return (
                <TextFormField
                    key={key}
                    label={label}
                    info={info}
                    name={champ.codeForm}
                    italicLabel={italicLabel}
                    required={isActuallyRequired(champ)}
                    maxLength={
                        maxLengths[champ.codeForm]
                            ? maxLengths[champ.codeForm]
                            : undefined
                    }
                />
            );
        }
    };

    const getTextForChamp = (champ, label, italicLabel, key) => {
        return (
            <Box pad="small">
                <Box direction="row">
                    <Text key={key}>{label}</Text>
                    {champ.obligatoireSurInternet && (
                        <Text
                            weight="bold"
                            size="large"
                            color="status-critical"
                        >
                            *
                        </Text>
                    )}
                </Box>
                {italicLabel && <Text>{italicLabel}</Text>}
            </Box>
        );
    };

    const getCcCondPart = (champ, label, key) => {
        return (
            <Box pad="small">
                <Box direction="row">
                    <Text key={key}>{label}</Text>
                    {champ.obligatoireSurInternet && (
                        <Text
                            weight="bold"
                            size="large"
                            color="status-critical"
                        >
                            *
                        </Text>
                    )}
                </Box>
            </Box>
        );
    };

    const getListForChamp = (champ, label, italicLabel, key) => {
        //Cas particulier de la direction d'emploi
        if (champ.tRefOngletInternetId === 3) {
            return (
                <DirectionAffectation
                    codeForm={champ.codeForm}
                    required={isActuallyRequired(champ)}
                    formulaire={props.formulaire}
                    key={key}
                    label={label}
                    italicLabel={italicLabel}
                    direction={props.referentiel.direction}
                    origineFonct={props.formulaire.listeAdminemp}
                />
            );
        } else {
            let options = getOptionsForChamp(champ.tRefOngletInternetId);
            if (options) {
                let required = isActuallyRequired(champ);
                let info = undefined;
                if (champ.codeForm === formCodes.PROFESSION_PERE) {
                    required = props.formulaire.nomPere !== "X";
                }

                if (champ.codeForm === formCodes.PROFESSION_MERE) {
                    required = props.formulaire.nomMere !== "X";
                }
                if (
                    champ.codeForm === formCodes.DIPLOME &&
                    props.labels?.conditionDiplome &&
                    props.formulaire.diplome &&
                    props.referentiel.conditionsSession?.trefDiplome &&
                    props.formulaire.diplome.ordre <
                        props.referentiel.conditionsSession.trefDiplome.ordre
                ) {
                    info = (
                        <Text weight="bold" color="sicmiorange">
                            {props.labels.conditionDiplome.replace(
                                "DIPLOME",
                                props.referentiel.conditionsSession.trefDiplome
                                    .libelle
                            )}
                        </Text>
                    );
                }
                return (
                    <ListFormField
                        name={champ.codeForm}
                        key={key}
                        label={label}
                        italicLabel={italicLabel}
                        required={required}
                        options={options.options}
                        info={info}
                        labelKey="libelle"
                        valueKey={options.valueKey}
                    />
                );
            }
        }

        return undefined;
    };

    const getListCheckboxForChamp = (champ, label, italicLabel, key) => {
        if (
            (champ.codeForm === formCodes.AMENAGEMENT_DEMANDE ||
                champ.codeForm === formCodes.AMENAGEMENT_DEMANDE_EXT) &&
            props.formulaire.personneHandicapee !== 1
        ) {
            return undefined;
        }

        let options = getOptionsForChamp(champ.tRefOngletInternetId);
        if (options?.options) {
            let required = champ.obligatoireSurInternet;

            return (
                <CheckboxGroupFormField
                    name={champ.codeForm}
                    key={key}
                    label={label}
                    italicLabel={italicLabel}
                    required={required}
                    options={options.options}
                    labelKey="libelle"
                    valueKey={options.valueKey}
                />
            );
        }

        return undefined;
    };

    const getOptionsForChamp = (tRefOngletInternetId) => {
        //Note : oui on doit surement pouvoir faire ca de manière plus élégante
        if (diplomeTypes.includes(tRefOngletInternetId)) {
            return {
                valueKey: "trefDiplomeId",
                options: props.referentiel.diplome,
            };
        } else if (professionTypes.includes(tRefOngletInternetId)) {
            return {
                valueKey: "trefProfessionId",
                options: props.referentiel.profession,
            };
        } else if (posAdminTypes.includes(tRefOngletInternetId)) {
            return {
                valueKey: "trefPosAdminId",
                options: props.referentiel.posAdmin,
            };
        } else if (paysAffectTypes.includes(tRefOngletInternetId)) {
            return {
                valueKey: "trefPaysId",
                options: props.referentiel.pays,
            };
        } else if (gradeTypes.includes(tRefOngletInternetId)) {
            return {
                valueKey: "trefGradeRecrutId",
                options: props.referentiel.gradeRecrut,
            };
        } else if (situationFamilialeTypes.includes(tRefOngletInternetId)) {
            return {
                valueKey: "trefSituationFamilialeId",
                options: props.referentiel.situationFamiliale,
            };
        } else if (situationMilitaireTypes.includes(tRefOngletInternetId)) {
            return {
                valueKey: "trefSituationMilId",
                options: props.referentiel.situationMil,
            };
        } else if (origineFoncTypes.includes(tRefOngletInternetId)) {
            return {
                valueKey: "trefOrigineFonctId",
                options: props.referentiel.origineFonct,
            };
        } else if (amenagementTypes.includes(tRefOngletInternetId)) {
            return {
                valueKey: "trefAmenagementId",
                options: props.referentiel.amenagements,
            };
        } else {
            return undefined;
        }
    };

    const isActuallyRequired = (champ) => {
        if (administrationFields.includes(champ.codeForm) && (!props.formulaire[formCodes.POSITION_ADMINISTRATIVE] || props.formulaire[formCodes.POSITION_ADMINISTRATIVE]?.absenceAffect === 1)) {
            return false;
        } else {
            return champ.obligatoireSurInternet;
        }
    };

    const getFieldFromChamp = (champ, i) => {
        let champLabel = champ.valeurLibelle
            ? champ.valeurLibelle
            : champ.libelle;
        const italic = /<i>(.*?)<\/i>/g.exec(champLabel);

        let italicLabel;
        let label;
        if (italic && italic.length >= 2) {
            italicLabel = italic[1];
            label = champLabel.split("<i>")[0];
        } else {
            label = champLabel;
        }

        let result;
        switch (champ.type) {
            case "DATE":
                result = (
                    <DateFormField
                        key={i}
                        name={champ.codeForm}
                        label={label}
                        required={champ.obligatoireSurInternet}
                        placeholder="jj/mm/aaaa"
                    />
                );
                break;
            case "ANNEE":
                result = (
                    <NumberFormField
                        key={i}
                        label={label}
                        name={champ.codeForm}
                        italicLabel={italicLabel}
                        required={champ.obligatoireSurInternet}
                        maxLength={4}
                        requiredLength={4}
                        placeholder="aaaa"
                    />
                );
                break;
            case "NUMERIQUE":
                if (
                    (champ.codeForm === formCodes.SPORTIF_HAUT_NIVEAU_ANNEE &&
                        props.formulaire.ancienSportifHautNiveau !== 1) ||
                    (champ.codeForm === formCodes.TRAVAILLEUR_HANDICAPE_ANNEE &&
                        props.formulaire.ancienTravaileurHandicape !== 1)
                ) {
                    result = undefined;
                } else {
                    let required = champ.obligatoireSurInternet;

                    if (
                        (champ.codeForm ===
                            formCodes.SPORTIF_HAUT_NIVEAU_ANNEE &&
                            props.formulaire[
                                formCodes.ANCIEN_SPORTIF_HAUT_NIVEAU
                            ] === 1) ||
                        (champ.codeForm ===
                            formCodes.TRAVAILLEUR_HANDICAPE_ANNEE &&
                            props.formulaire[
                                formCodes.ANCIEN_TRAVAILLEUR_HANDICAPE
                            ] === 1)
                    ) {
                        required = true;
                    }
                    result = (
                        <NumberFormField
                            key={i}
                            label={label}
                            name={champ.codeForm}
                            italicLabel={italicLabel}
                            maxLength={
                                maxLengths[champ.codeForm]
                                    ? maxLengths[champ.codeForm]
                                    : undefined
                            }
                            required={required}
                        />
                    );
                    if (
                        champ.codeForm === formCodes.ENGAGEMENT_DRAPEAUX_ANNEE
                    ) {
                        result = (
                            <>
                                <div>
                                    <Text weight="bold" margin="small">
                                        {
                                            messagesAutresRens.DUREE_ENGAGEMENT_DRAPEAUX
                                        }
                                    </Text>
                                </div>
                                {result}
                            </>
                        );
                    }
                }

                break;
            case "LISTE":
                result = getListForChamp(champ, label, italicLabel, i);
                break;
            case "LISTE_CASE_A_COCHER":
                result = getListCheckboxForChamp(champ, label, italicLabel, i);
                break;
            case "AUCUN":
                result = getTextForChamp(champ, label, italicLabel, i);
                break;
            case "CASE_A_COCHER":
                label = label.replace("|$#@*µ§|", "<br>");
                result = (
                    <CheckboxFormField
                        key={i}
                        label={label}
                        name={champ.codeForm}
                        italicLabel={italicLabel}
                        maxLength={
                            maxLengths[champ.codeForm]
                                ? maxLengths[champ.codeForm]
                                : undefined
                        }
                        required={true}
                    />
                );
                break;
            case "BOUTON_RADIO":
            case "OUI_NON":
                result = getRadioBtnForChamp(champ, label, italicLabel, i);
                break;
            default:
                if (
                    (!props.formulaire.listePaysaffect ||
                        props.formulaire.listePaysaffect.trefPaysId !== 33) &&
                    (champ.codeForm === formCodes.CODE_POSTAL_AFFECTATION ||
                        champ.codeForm === formCodes.COMMUNE_AFFECTATION)
                ) {
                    result = undefined;
                } else {
                    result = getTextInputForChamp(champ, label, italicLabel, i);
                }
        }

        return result;
    };

    const screenSize = useScreenSize();
    return (
        <>
            {screenSize !== "small" && (
                <Box border direction="row" pad="small" justify="between">
                    <Box direction="column" gap="medium" width="40%">
                        {balancedSize > 0 &&
                            props.champs
                                .slice(0, Math.floor(balancedSize / 2))
                                .map((champ, i) => getFieldFromChamp(champ, i))}
                    </Box>
                    <Box direction="column" width="40%">
                        {balancedSize > 0 &&
                            props.champs
                                .slice(Math.floor(balancedSize / 2))
                                .map((champ, i) => getFieldFromChamp(champ, i))}
                    </Box>
                </Box>
            )}
            {screenSize === "small" && (
                <Box
                    border="top"
                    direction="column"
                    width="100%"
                    margin={{ bottom: "xlarge" }}
                    pad={{ top: "medium" }}
                    gap="medium"
                >
                    {props.champs.length > 0 &&
                        props.champs.map((champ, i) =>
                            getFieldFromChamp(champ, i)
                        )}
                </Box>
            )}

            <button
                id="autresRensBtn"
                type="submit"
                style={{ display: "none" }}
            />
        </>
    );
};
