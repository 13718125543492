import { paths } from "../paths";
import { injectAuthHeader, injectCsrfHeader } from "./header";
import { store } from "./store";

export const agentLogin = {
    getInfosCompte: (token, callback) => {
        fetch(paths.API_COMPTE_INFOS, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return undefined;
            })
            .then((data) => {
                callback(data);
            })
            .catch((err) => {
                callback(err);
            });
    },
    resetPassword: (email, callback) => {
        const postData = {
            email: email ? email : "",
        };
        const formBody = Object.keys(postData)
            .map(
                (key) =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(postData[key])
            )
            .join("&");

        fetch(paths.API_COMPTE_MDP_OUBLIE_POST, {
            method: "POST",
            headers: injectAuthHeader(injectCsrfHeader({
                "Content-Type": "application/x-www-form-urlencoded",
            })),
            body: formBody,
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                return response;
            })
            .then((result) => {
                callback(result);
            }).catch((error) => {
                console.log(error);
                callback(false);
            });
    },
    getFCLogoutUrl: (token, callback) => {
        fetch(paths.API_FRANCE_CONNECT_LOGOUT, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return response;
            })
            .then((data) => {
                callback(data);
            });
    },
    updateAccount: (
        currentPwd: string,
        newPwd: string,
        email: string,
        lastname: string,
        firstname: string,
        callback: Function
    ) => {
        const postData = {
            currentPwd: currentPwd,
            newPwd: newPwd,
            lastname: lastname,
            firstname: firstname,
            email: email,
        };
        const formBody = Object.keys(postData)
            .map(
                (key) =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(postData[key])
            )
            .join("&");

        fetch(paths.API_COMPTE_UPDATE, {
            method: "POST",
            headers: injectAuthHeader(injectCsrfHeader({
                "Content-Type": "application/x-www-form-urlencoded",
            })),
            body: formBody,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(String(response.status));
                }
                return response.json();
            })
            .then((data) => {
                callback(data);
            })
            .catch((error) => {
                callback(error.message);
            });
    },
    updateEmail: (email: string, callback: Function) => {
        fetch(paths.API_COMPTE_EMAIL_UPDATE, {
            method: "POST",
            headers: injectAuthHeader(injectCsrfHeader({
                "Content-Type": "application/x-www-form-urlencoded",
            })),
            body: encodeURIComponent("email") + "=" + encodeURIComponent(email),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(String(response.status));
                }
                return response.json();
            })
            .then((data) => {
                callback(data);
            })
            .catch((error) => {
                callback(error.message);
            });
    },
    login: (email: string, password: string, callback: Function) => {
        const postData = {
            "email": email,
            "password": password,
        };
        
        const formBody = Object.keys(postData)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(postData[key]))
            .join('&');
        
        fetch(paths.API_COMPTE_LOGIN, {
            method: "POST",
            headers: injectCsrfHeader({
              'Content-Type': 'application/x-www-form-urlencoded'
            }),
            body: formBody
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(String(response.status));
            }
            return response.json();
        })
        .then((data) => {
            callback(data);
        })
        .catch((error) => {
            callback(error.message);
        });
    }
};
