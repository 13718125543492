import { Box, Button, Text } from "grommet";

import { VerticalDivider } from "../components/VerticalDivider";
import { Logo } from "../Logo";
import { LocaleDate } from "../components/LocaleDate";
import { getTheme } from "../etapeThemes";
import { isLastEpreuveDone } from "../epreuvesHelper";
import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";
import { useEffect, useState } from "react";
import { paths } from "../../paths";
import { downloadUrl } from "../downloadUrl";

export const Resultats = (props) => {

    const [releveUrl, setReleveUrl] = useState(undefined);
    
    const resultatTheme = (() => {
        switch (props.etape.etat) {
            case "encours":
                if (!isLastEpreuveDone(props.etape.epreuves)) {
                    return getTheme(
                        "pending",
                        props.header,
                        messagesEspaceCandidat.RESULTAT_ETAPE_PENDING
                    );
                }

                return getTheme("current", props.header, messagesEspaceCandidat.RESULTAT_ETAPE_PENDING);
            case "close":
                if (props.etape.affichageResultat) {
                    if (props.etape.elimine) {
                        return getTheme(
                            "doneKO",
                            props.header,
                            messagesEspaceCandidat.RESULTAT_ETAPE_KO
                        );
                    }

                    let resultMsg;
                    if (props.etape.derniere) {
                        resultMsg = props.etape.admisLC
                            ? messagesEspaceCandidat.RESULTAT_ETAPE_OK_LAST_COMP
                            : messagesEspaceCandidat.RESULTAT_ETAPE_OK_LAST;
                    } else {
                        resultMsg = messagesEspaceCandidat.RESULTAT_ETAPE_OK;
                    }

                    return getTheme("doneOK", props.header, resultMsg);
                }
                return getTheme(
                    "doneOK",
                    props.header,
                    messagesEspaceCandidat.RESULTAT_ETAPE_HIDDEN
                );
            default:
                return getTheme("pending", props.header, props.body);
        }
    })();

    useEffect(() => {
        if (props.etape?.releveDate && props.idDossier) {
            const params = {
                idSession: props.idSession,
                idEtape: props.etape?.idEtape,
                idDossier: props.idDossier,
            };
            setReleveUrl(
                downloadUrl(paths.API_CANDIDAT_RELEVE, params)
            );
        }
    }, [props.etape, props.idDossier, props.idSession]);

    function downloadReleve() {
        window.open(releveUrl, "_blank").focus();
    }

    return (
        <Box direction="row" gap="small" fill="horizontal">
            <Box direction="column" width={{ min: "xxsmall", max: "xxsmall" }}>
                <Logo
                    background={resultatTheme.theme.backgroundColor}
                    border={resultatTheme.theme.border}
                >
                    {resultatTheme.theme.logo}
                </Logo>
                <VerticalDivider
                    background={{
                        color: resultatTheme.theme.dividerColor,
                        doted: resultatTheme.theme.doted,
                    }}
                />
            </Box>
            <Box direction="column" gap="small" fill="horizontal">
                <Box height={{ min: "48px" }} justify="center">
                    <LocaleDate
                        date={props.dateValidation}
                        color="grey"
                        locale="fr-FR"
                    />
                    <Text
                        weight="bold"
                        size="large"
                        color={resultatTheme.theme.headerColor}
                    >
                        {resultatTheme.header}
                    </Text>
                    <Text
                        size="small"
                        color={resultatTheme.theme.headerColor}
                        style={{fontStyle: "italic"}}
                    >
                        {messagesEspaceCandidat.RESULTAT_SUBHEADER_INFO_LEGALE}
                    </Text>
                </Box>
                {releveUrl && (
                    <Box align="start">
                        <Button
                            a11yTitle={
                                messagesEspaceCandidat.DOWNLOAD_RELEVE_ARIA
                            }
                            color="sicmiblack"
                            label={messagesEspaceCandidat.DOWNLOAD_RELEVE}
                            onClick={() => downloadReleve()}
                        />
                    </Box>
                )}

                <Box
                    border={{ size: "medium" }}
                    round="small"
                    margin={{ bottom: "small" }}
                >
                    <Box pad="xsmall" round="small" background="sicmiwhite">
                        <Text
                            color={resultatTheme.theme.headerColor}
                            margin="xsmall"
                        >
                            {resultatTheme.body}
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
