// @ts-nocheck
// Obligatoire pour pouvoir ajouter les tags Eulerian sans faire crasher la compilation
import { Box, Grommet, Main } from "grommet";
import { useEffect, useState } from "react";

import { FooterSicmi } from "../common/FooterSicmi";
import { HeaderSicmi } from "../common/HeaderSicmi";
import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";
import { paths } from "../paths";
import { theme } from "../theme";
import { agentInscriptions } from "./agentInscriptions";
import { Toast } from "../common/Toast";
import { ChoixBureau } from "./ChoixBureau";
import { PageDroits } from "./PageDroits";
import { PostValidation } from "./PostValidation";

export const FinalStep = (props) => {
    const breadcrumbs = [
        { label: "Accueil", href: paths.HOME },
        { label: "Inscription à une session" },
    ];

    const [toastSaveErrorCount, setToastSaveErrorCount] = useState(0);
    const [validationDone, setValidationDone] = useState(false);
    const [choixBureau, setChoixBureau] = useState(undefined);
    const [disableButton, setDisableButton] = useState(false);

    const valider = (idBurRecrut: any) => {
        setDisableButton(true);
        agentInscriptions.validerDossier(
            props.params.idSession,
            idBurRecrut,
            (content) => {
                if (content === true) {
                    setValidationDone(true);
                } else {
                    setDisableButton(false);
                    setChoixBureau(content);
                }
            },
            (_error) => {
                setToastSaveErrorCount(toastSaveErrorCount + 1);
                setDisableButton(false);
            }
        );
    };

    useEffect(() => {
        if (typeof window["EA_push"] === "function") {
            EA_push([
                "path", "/inscription/fin",
                "pagelabel", "Fin de formulaire - Droits de modification",
            ]);
        }
    }, []);

    return (
        <Grommet full theme={theme}>
            <HeaderSicmi
                title={messagesInscriptionSession.PAGE_TITLE}
                breadcrumbs={breadcrumbs}
            />
            <Main>
                <Box flex overflow="auto">
                    <Toast
                        count={toastSaveErrorCount}
                        status="status-error"
                        text={messagesInscriptionSession.TOAST_SUBMIT_ERROR}
                        timeout={5000}
                    />
                    {!validationDone && !choixBureau && (
                        <PageDroits
                            idSession={props.params.idSession}
                            onAccept={valider}
                            disableButton={disableButton}
                        />
                    )}
                    {!validationDone &&
                        choixBureau &&
                        choixBureau.bureauxParticipants?.length > 0 && (
                            <ChoixBureau
                                message={choixBureau.message}
                                bureauxParticipants={
                                    choixBureau.bureauxParticipants
                                }
                                onValidation={valider}
                                disableButton={disableButton}
                            />
                        )}

                    {validationDone && (
                        <PostValidation idSession={props.params.idSession} />
                    )}
                </Box>
            </Main>

            <FooterSicmi />
        </Grommet>
    );
};
